<template>
  <a-modal
    class="global-range-modal"
    centered
    :visible="visible"
    :width="655"
    @cancel="handledClose('close')"
    @ok="handledClose('ok')"
  >
    <a-row type="flex" class="model-row">
      <a-col :span="12" class="model-col">
        <!-- search -->
        <div class="model-search">
          <a-input
            class="model-input"
            v-model:value.trim="seachVal"
            :placeholder="searchPlaceholderText"
            @change="toSearch"
            allow-clear
          >
            <template #prefix>
              <SearchOutlined style="color: rgba(0, 0, 0, 0.25)" />
            </template>
          </a-input>
        </div>
        <!-- 面包屑 -->
        <div class="model-breadcrumb">
          <slot name="breadcrumb">
            印章列表
          </slot>
        </div>
        <!-- 全选 -->
        <span :class="['all-sel']" @click="selectAll" v-if="checkAll">
          全选
        </span>
        <!-- 待选择列表 -->
        <div class="left-list">
          <template v-if="renderList.length">
            <div v-for="item in renderList" :key="item.sealId" class="model-item">
              <a-checkbox :checked="getCheckState(item)" class="model-checkbox" @change="handledChange(item)">
                <div class="model-checkbox-item">
                  <img class="model-checkbox-img" :src="getSealIcon(item)" />
                  <div :class="['model-checkbox-name', 'single-wrap', item.status == 2 && 'view-width']">
                    <a-tooltip placement="topLeft" :title="item.sealName" v-if="item.sealName.length > 8">
                      {{ item.sealName }}
                    </a-tooltip>
                    <div v-else>
                      {{ item.sealName }}
                    </div>
                  </div>
                  <div class="deactivate" v-if="item.status == 2">停用</div>
                </div>
              </a-checkbox>
            </div>
          </template>
          <a-empty v-else :image="simpleImage" />
        </div>
      </a-col>
      <!-- 已选列表 -->
      <a-col :span="12" class="model-col model-right">
        <div class="flex-layout" style="justify-content: space-between">
          <h4>已选：{{ checkedStaff.length }} 个</h4>
          <span class="checked-progress" v-if="checkAllLimit">{{ checkedStaff.length }}/{{ checkAllLimit }}</span>
        </div>
        <div class="model-checked" v-if="checkedStaff.length">
          <div v-for="(item, index) in checkedStaff" :key="item.sealId" class="model-checked-row">
            <div class="single-wrap checked-item-left">
              <img class="model-checked-img" :src="getSealIcon(item)" />
              <div :class="['model-checkbox-name', 'single-wrap', item.status == 2 && 'view-width']">
                <a-tooltip placement="topLeft" :title="item.sealName" v-if="item.sealName.length > 8">
                  {{ item.sealName }}
                </a-tooltip>
                <div v-else>
                  {{ item.sealName }}
                </div>
              </div>
              <div class="deactivate" v-if="item.status == 2">停用</div>
            </div>
            <div @click="handledDelete(item, index)" class="model-del">
              <CloseCircleFilled />
            </div>
          </div>
        </div>
        <a-empty v-else :image="simpleImage" style="margin-top: 150px" />
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import { reactive, toRefs, ref, onUnmounted, defineComponent, watch } from 'vue'
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons-vue'
import { Empty } from 'ant-design-vue'
import { cmsNotice } from '@/utils/utils'
export default defineComponent({
  name: 'RangeModal',
  props: {
    searchPlaceholderText: {
      type: String,
      default: '请输入'
    },
    selectData: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Boolean
    },
    // 全选
    checkAll: {
      type: Boolean,
      default: false
    },
    checkAllLimit: {
      required: false,
      type: Number
    },
    //以选择的印章数据
    alreadySelectData: {
      type: Array,
      default: () => []
    }
  },
  components: {
    CloseCircleFilled,
    SearchOutlined
  },
  emits: ['onCancel', 'checkData', 'update:visible'],
  setup(props, { emit }) {
    const state = reactive({
      seachVal: '', // 搜索关键词
      checkedStaff: [],
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      renderList: []
    })
    const time = ref(null)

    // 获取左list单项选中状态，判断右列表是否存在该数据
    const getCheckState = item => {
      return state.checkedStaff.findIndex(it => it.sealId === item.id) >= 0
    }
    // 切换选中状态，在右列表中删掉或增加数据，选中状态还是根据 getCheckState() 判断
    const handledChange = e => {
      const checked = getCheckState(e)
      if (checked) {
        const index = state.checkedStaff.findIndex(it => it.sealId === e.id)
        state.checkedStaff.splice(index, 1)
      } else {
        if (props.checkAllLimit && state.checkedStaff.length === props.checkAllLimit)
          return cmsNotice('warning', '当前选择的印章已达上限')
        console.log('e', e)
        state.checkedStaff.push({
          ...e,
          sealId: e.id
        })
      }
    }

    //
    const handledDelete = (e, i) => {
      state.checkedStaff.splice(i, 1)
    }

    //搜索
    const toSearch = e => {
      if (time.value) {
        clearTimeout(time.value)
      }
      time.value = setTimeout(() => {
        let data = e.target.value
        if (data === '') {
          state.renderList = props.selectData?.map(item => item)
          return
        }
        searchData(data)
      }, 800)
    }

    //搜索
    const searchData = data => {
      state.renderList = props.selectData.filter(item => item.sealName.indexOf(data) > -1)
    }

    // 确定or取消
    const handledClose = type => {
      if (type === 'ok') {
        emit('checkData', [...state.checkedStaff])
      }
      state.seachVal = ''
      state.renderList = []
      state.checkedStaff = []
      if (type == 'close') {
        emit('onCancel')
      }
      emit('update:visible', false)
    }

    // 全选
    const selectAll = () => {
      const flag = isSelectedFlag()
      console.log(flag)
      if (flag) return
      const limitNum = props.checkAllLimit // 限制
      if (props.checkAll && !limitNum) {
        state.checkedStaff = props.selectData?.map(item => {
          return {
            ...item,
            sealId: item.id
          }
        })
        return
      }
      // 个数限制
      const checkedStaffLength = state.checkedStaff.length //  已选的长度
      checkedStaffLength === limitNum && cmsNotice('warning', '当前选择的印章已达上限')
      let addIndex = 0
      if (checkedStaffLength >= limitNum) return
      state.renderList.forEach(e => {
        if (getCheckState(e)) return
        addIndex++
        if (addIndex <= limitNum - checkedStaffLength) {
          state.checkedStaff.push({
            ...e,
            sealId: e.id
          })
        }
      })
    }

    const isSelectedFlag = () => {
      const flag = props.checkAllLimit
        ? state.checkedStaff.length === props.checkAllLimit
        : state.renderList.length == state.checkedStaff.length
      return flag
    }

    const getSealIcon = item => {
      return item.status == 2
        ? require('@/assets/images/business/disabled_seal.png')
        : require('@/assets/images/business/active_seal.png')
    }

    watch(
      () => props.visible,
      () => {
        if (props.visible) {
          state.checkedStaff = props.alreadySelectData?.map(item => item)
          state.renderList = props.selectData?.map(item => item)
        }
      }
    )

    onUnmounted(() => {
      clearTimeout(time.value)
    })

    return {
      ...toRefs(state),
      getCheckState,
      handledChange,
      toSearch,
      handledDelete,
      handledClose,
      selectAll,
      isSelectedFlag,
      getSealIcon
    }
  }
})
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>
