<!--
 * @version: 3.0
 * @Date: 2023-04-17 18:01:08
 * @LastEditors: chenxu chenxu@libawall.cn
-->
<template>
  <div class="basic">
    <a-form :model="basicForm" layout="vertical" :rules="rules" ref="formRef">
      <a-form-item label="图标" name="icon">
        <div class="icon" @mouseenter="mouseenter" @mouseleave="mouseleave" @click="showIcon = true">
          <PlusOutlined class="icon-initial" v-if="icon === ''" />
          <img v-else :src="icon" alt class="icon-img" />
          <div class="icon-hover" v-show="isMouse">修改</div>
        </div>
      </a-form-item>
      <a-form-item label="流程名称" name="name">
        <a-input v-model:value="basicForm.name" placeholder="请输入流程名称" :maxLength="30" />
      </a-form-item>
      <a-form-item label="业务类型" name="processType">
        <a-select
          v-model:value="basicForm.processType"
          placeholder="请选择关联印章"
          @change="changeProcessType"
          class="select"
        >
          <a-select-option :value="1">普通申请</a-select-option>
          <a-select-option :value="2">自助申请</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="说明" name="remark">
        <a-input v-model:value="basicForm.remark" placeholder="请输入说明" :maxLength="60" />
      </a-form-item>
      <a-form-item label="关联印章" name="sealList">
        <a-select
          v-model:value="changeSeal"
          placeholder="请选择关联印章"
          @select="sealChange"
          :mode="sealSelectType"
          class="select"
          @deselect="value => delSelect(value, 'seal')"
        >
          <template #suffixIcon>
            <DownOutlined />
          </template>
          <a-select-option v-for="item in sealSelectList" :key="item.id" :value="item.id" class="option">
            <div>{{ item.name }}</div>
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 适用范围 -->
      <a-form-item label="适用范围" name="scopeList" class="range">
        <a-select
          v-model:value="scopeList"
          placeholder="请选择适用范围"
          @select="changePri"
          class="select"
          :mode="scopeList === 'all' || scopeList === '' ? null : 'multiple'"
          @deselect="value => delSelect(value, 'range')"
        >
          <a-select-option :value="item.key" v-for="item in valueModal" :key="item.key">{{
            item.title
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="业务流程负责人" name="custodianList">
        <div class="proces">
          <div class="pro-item" v-for="(el, index) in custodianList" :key="el.dataId">
            <img :src="el.icon" alt class="pro-item-icon" />
            <i class="iconfont icon-cha1 remove-arrow" @click="deleteSta(index, 1)" />
            <div class="pro-name" v-if="el.staffName?.length < 4">{{ el.staffName }}</div>
            <a-popover v-else :getPopupContainer="e => e.parentNode" overlayClassName="lang-name-pop">
              <div class="lang-text-name">{{ el.staffName }}</div>
              <template #content>
                <div>{{ el.staffName }}</div>
              </template>
            </a-popover>
          </div>
          <img
            v-if="custodianList?.length < 20"
            @click="showProModal(1)"
            src="@/assets/images/business/chang-process.png"
            alt
            class="process-up"
          />
        </div>
      </a-form-item>
      <a-form-item label="远程确认人" name="remoteAuditor" v-if="isService">
        <!-- 下拉框 -->
        <a-select
          v-model:value="remoteAuditor"
          placeholder="请选择远程确认人"
          @select="changePri"
          class="remote"
          :mode="remoteAuditor === 'every' || remoteAuditor === '' ? null : 'multiple'"
          @deselect="value => delSelect(value, 'remote')"
        >
          <a-select-option :value="item.key" v-for="item in remoteModal" :key="item.key">{{
            item.title
          }}</a-select-option>
        </a-select>
        <a-tooltip placement="top" class="tips-remote">
          <template #title>
            <span style="display:block;text-align:center;font-size: 14px;"
              >申请人发起流程时，可在配置的远程确认人中进行选择</span
            >
          </template>
          <QuestionCircleFilled class="charts-icon" style="margin-left: 8px" />
        </a-tooltip>
      </a-form-item>
      <a-form-item label="补盖审批人设置" name="coverList" class="approval">
        <a-switch v-model:checked="checked" class="checked" @change="checkChange" />
        <!-- 下拉框 -->
        <a-select
          v-if="checked"
          v-model:value="coverList"
          placeholder="请选择补盖审批人"
          @select="changePri"
          class="select"
          :mode="coverList === 'business' || coverList === '' ? null : 'multiple'"
          @deselect="value => delSelect(value, 'cover')"
        >
          <a-select-option :value="item.key" v-for="item in coverModal" :key="item.key">{{
            item.title
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <!-- <a-form-item label="" name="photo" :style="{ 'margin-top': checked ? '-6px' : '-16px' }">
        <div>
          <span>用印前是否必须拍照</span>
          <a-switch
            :checked="basicForm.beforeSealPhoto === 1"
            class="switch"
            @change="value => setSealChange(value, 'before')"
          ></a-switch>
        </div>
        <div class="snap" v-if="mainSwitch && isBind">
          <div>
            <span>用印后自动抓拍</span>
            <a-switch
              v-model:checked="afterSealSnap"
              class="switch"
              @change="value => setSealChange(value, 'after')"
            ></a-switch>
          </div>
          <div class="snap-right" v-if="afterSealSnap">
            <span>自动抓拍时间间隔： {{ snapInterval ?? '1.5' }}s</span>
            <div style="margin-right:8px;display:inline-block"></div>
            <a-tooltip placement="top" class="tips-text">
              <template #title>
                <span style="display:block;text-align:center;font-size: 14px;"
                  >时间间隔可在企业管理-参数设置中配置</span
                >
              </template>
              <QuestionCircleFilled class="charts-icon" />
            </a-tooltip>
          </div>
        </div>
      </a-form-item> -->
      <!-- <a-form-item label="" name="" class="form-item">
        <div>
          <span>是否强制补拍</span>
          <a-switch v-model:checked="makeUp" @click="alterConfirm()" class="switch" />
          <a-tooltip placement="top" class="tips-text">
            <template #title>
              <span style="display:block;text-align:center;font-size: 14px;"
                >开启后，盖印人一旦盖印必须补拍，否则无法使用其他待用印</span
              >
            </template>
            <QuestionCircleFilled class="charts-icon" style="margin-left: 8px" />
          </a-tooltip>
        </div>
      </a-form-item> -->
    </a-form>
    <!-- 选择图标弹窗 -->
    <a-modal
      :title="null"
      v-model:visible="showIcon"
      :footer="null"
      :closable="false"
      :mask="false"
      :maskClosable="true"
      width="336px"
    >
      <div class="modal">
        <div class="modal-item" v-for="item in iconList" :key="item.key" @click="getIcon(item)">
          <img :src="item.icon" alt class="item-icon" />
          <img v-if="icon === item.icon" src="@/assets/images/business/select.png" alt class="icon-sel" />
        </div>
      </div>
    </a-modal>

    <a-modal
      :visible="state.modalShow"
      :title="state.titles[currentType]"
      :maskClosable="true"
      width="655px"
      class="global-range-modal"
      @ok="handledOk(currentType)"
      @cancel="closeModal(currentType)"
      centered
    >
      <GlobalSelector
        ref="globalSelector"
        :visible="state.modalShow"
        :selectorInfo="state.config[currentType]"
        @getSearchInfo="getSearchInfo"
        :renderList="state.checkList"
        :selectedList="selectedList"
        @toNext="tolowerMdel"
        @changeData="changeData"
      >
        <template #customTitle v-if="currentType === 'seal'">
          印章列表
        </template>
        <template #customInfo="{ currentItem }" v-if="currentType === 'seal'">
          <div v-if="currentItem['status'] == 2" class="deactivate">停用</div>
        </template>
        <!--TODO：联调印章所属组织字段-->
        <template #customDesc="{ currentItem }" v-if="currentType === 'seal'">
          <div class="dept-name">{{ currentItem['departmentName'] }}</div>
        </template>
      </GlobalSelector>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, defineProps, watch, createVNode, computed, defineExpose, onMounted, onUpdated, reactive } from 'vue'
import RangeModal from '@/components/rangeModal'
import { Modal } from 'ant-design-vue'
import {
  PlusOutlined,
  DownOutlined,
  CloseOutlined,
  QuestionCircleFilled,
  ExclamationOutlined
} from '@ant-design/icons-vue'
import { getSealList, getProcessName } from '@/apis/process'
import { useStore } from 'vuex'
import Footer from '@/components/Footer/index'
import { useRoute } from 'vue-router'
import { getSearchData, getConfigure, getBind, transformPeople, getDepartmentOrStaff } from '@/apis/businessManage'
import { formateListData, transformData } from '@/utils/selectorListFormate.js'

import { loadInitFrom } from '@/utils/hooks'
import SealSelectModal from '@/components/SealSelectModal/index.vue'
import personModal from './personModal.vue'
import GlobalSelector from '@/components/GlobalSelector'
import { getStaffRemoteList } from '@/apis/businessManage'

const store = useStore()
const enterpriseId = computed(() => store.state.user.userInfo.enterpriseId)
const props = defineProps(['isDraft'])
// 'isBind'

const icon = ref('')
const scopeApplication = ref([]) // 选择的适用范围
const coverPersonList = ref([]) // 选择的补盖审批人列表
const remotePersonList = ref([]) // 选择的远程确认人列表
const custodianList = ref([]) // 流程负责人列表
const checkAllLimit = ref(null) // 弹窗限制
const currentType = ref('')
// const isShowCover = ref(false) // 补盖审批人弹窗
const state = reactive({
  modalShow: false, // 弹窗展示
  // 弹窗配置
  selectorInfo: {
    isShowSelectedList: true, //[是否展示已选的列表]
    isShowBreadcrumb: true, //[是否显示面包屑]
    placeholder: '请输入员工姓名或部门'
  },
  checkList: [], // 所有可选
  // cover 补盖      business 流程名称负责人      range 适用范围        remote 远程确认人
  type: '',
  config: {
    // 补盖审批人设置【指定人员】
    cover: {
      isShowSelectedList: true, //[是否展示已选的列表]
      isShowBreadcrumb: true, //[是否显示面包屑]
      placeholder: '请输入员工姓名或部门',
      abelCheckAll: true,
      ableSelectDep: true,
      ableChangeData: true
    },
    //流程名称负责人
    business: {
      isShowSelectedList: true, //[是否展示已选的列表]
      isShowBreadcrumb: true, //[是否显示面包屑]
      ableSelectDep: true, //[能否选择部门]
      limitCount: 10, // [限制数量]
      abelCheckAll: true, //[是否显示全选]
      placeholder: '请输入员工姓名或部门',
      ableChangeData: true
    },
    //适用范围【指定部门或人员】
    range: {
      isShowSelectedList: true, //[是否展示已选的列表]
      isShowBreadcrumb: true, //[是否显示面包屑]
      ableSelectDep: true, //[能否选择部门]
      placeholder: '请输入员工姓名或部门',
      abelCheckAll: true,
      ableChangeData: true
    },
    //关联印章【指定印章】
    seal: {
      isShowBreadcrumb: false,
      placeholder: '请输入印章名称查询',
      unit: '枚'
    },
    // 远程确认人
    remote: {
      isShowSelectedList: true, //[是否展示已选的列表]
      isShowBreadcrumb: true, //[是否显示面包屑]
      ableSelectDep: true, //[能否选择部门]
      placeholder: '请输入员工姓名',
      abelCheckAll: true,
      ableChangeData: true
    }
  },
  // 弹窗顶部
  titles: {
    cover: '选择补盖审批人',
    business: '选择业务流程负责人',
    range: '选择部门或人员',
    seal: '请选择关联印章',
    remote: '请选择远程确认人'
  }
})

const basicForm = computed({
  get() {
    return store.state.process.basic
  },
  set(val) {
    store.state.process.basic = val
  }
})

const formRef = ref()
const sonRef = ref()
const sealList = ref([]) // 接口印章列表
const changeSeal = ref([]) //回显的印章数据（sealName）
const isMouse = ref(false) // 图标修改是否显示
const showIcon = ref(false) // 弹窗显示
const selectedList = ref([]) // 弹窗选择列表
const RangeModalVisible = ref(false) // 弹窗
const checkAll = ref(false) // 是否全选
const modalTitle = ref('')
const checked = ref(false) // 补盖审批是否开启
// const afterSealSnap = ref(true) // 是否抓拍
// const snapInterval = ref('1.5')
const valueModal = computed(() => {
  return basicForm.value.processType == 1
    ? [
        { title: '所有人', key: 'all' },
        { title: '指定部门或人员', key: 'personnel' }
      ]
    : [
        { title: '所有人', key: 'all' },
        { title: '指定部门', key: 'personnel' }
      ]
})
const coverModal = ref([
  { title: '流程负责人', key: 'business' },
  { title: '指定人员', key: 'appointPersonnel' }
])
const remoteModal = ref([
  { title: '所有人', key: 'every' },
  { title: '指定人员', key: 'designee' }
])
const loading = ref(false)
const modalType = ref(1) // 弹窗类型 1 流程负责人 2 适用范围 3 补盖审批人 4远程确认人
const scopeList = ref('all')
const coverList = ref('business') // 补盖审批人
const remoteAuditor = ref('every') // 远程确认人
const globalSelector = ref(null) // 弹窗
const $route = useRoute()
const iconList = ref([
  { icon: require('@/assets/images/business/icon_1.png'), key: 'ICON_1' },
  { icon: require('@/assets/images/business/icon_2.png'), key: 'ICON_2' },
  { icon: require('@/assets/images/business/icon_3.png'), key: 'ICON_3' },
  { icon: require('@/assets/images/business/icon_4.png'), key: 'ICON_4' },
  { icon: require('@/assets/images/business/icon_5.png'), key: 'ICON_5' },
  { icon: require('@/assets/images/business/icon_6.png'), key: 'ICON_6' },
  { icon: require('@/assets/images/business/icon_7.png'), key: 'ICON_7' },
  { icon: require('@/assets/images/business/icon_8.png'), key: 'ICON_8' },
  { icon: require('@/assets/images/business/icon_9.png'), key: 'ICON_9' },
  { icon: require('@/assets/images/business/icon_10.png'), key: 'ICON_10' },
  { icon: require('@/assets/images/business/icon_11.png'), key: 'ICON_11' },
  { icon: require('@/assets/images/business/icon_12.png'), key: 'ICON_12' }
])

// const mainSwitch = ref(true)
// const makeUp = ref(false)
const staffRemoteList = ref([]) // 所有人的staffid
const isService = ref(true) // 是否是自助申请

//关联印章options
const sealSelectList = ref([
  {
    id: 1,
    name: '所有印章'
  },
  {
    id: 2,
    name: '指定印章'
  }
])
//选择印章select类型
const sealSelectType = ref('multiple')

//选择的印章数据（用于选择指定印章）
const alreadySelectData = ref([])
const processStaff = ref([]) // 所有人
const selectorType = ref(1)

onMounted(async () => {
  // getProcessStaff()
  // 获取参数
  // const res = await getConfigure()
  // console.log('00101001011',res)
  // if (res.success) {
  // mainSwitch.value = res.data.afterSealSnap === 1
  // snapInterval.value = res.data.snapInterval
  // }

  if ($route.query.processId) return
  getInitList()
})

// 获取所有人
const getProcessStaff = async () => {
  // 获取自由流程的审批人
  const res = await getStaffRemoteList()

  console.log('basicForm.value', basicForm.value)
  res.data &&
    res.data.forEach(item => {
      staffRemoteList.value.push(item.staffId)
    })
  console.log('staffRemoteList.value', staffRemoteList.value)
  console.log('res.data', res.data)
}

watch(
  () => basicForm.value,
  value => {
    console.log('监听的值', value)
    if ($route.query.processId) {
      // console.log(mainSwitch.value, snapInterval.value)
      const iconL = iconList.value.filter(el => value?.icon === el.icon || value?.icon === el.key)
      icon.value = iconL[0]?.icon ?? '' // 图标
      checked.value = value.coverSwitch === 1 // 审批人设置开关
      // 印章列表
      changeSeal.value = value.sealRange == 1 ? 1 : value.sealList.map(item => item.sealName)
      sealSelectType.value = value.sealRange == 1 ? null : 'multiple'
      isService.value = value.processType === 1 // 1是普通申请
      //选择印章的完整信息
      console.log('初始值', value.sealList)
      alreadySelectData.value = value.sealList.map(item => {
        item.islower = false
        item.dataName = item.sealName
        item.dataType = 1
        item.dataId = item.sealId
        item.icon =
          item.status == 2
            ? require('@/assets/images/business/disabled_seal.png')
            : require('@/assets/images/business/active_seal.png')
        return item
      })
      console.log('选中回显的弹窗值', alreadySelectData.value)

      custodianList.value = []
      // 流程负责人
      custodianList.value = value.custodianList.map(item => {
        return {
          dataId: item.staffId,
          dataName: item.staffName,
          dataType: Number(item.type),
          icon: item?.fullAvatarId || item?.fullAvatar || require('@/assets/images/default-avatar.png'),
          ...item
        }
      })
      value.custodianList = value.custodianList.map(item => {
        return {
          staffId: item.staffId,
          staffName: item.staffName,
          ...item
        }
      })
      // 适用范围
      scopeApplication.value = []
      if (value.scopeType === 2) {
        scopeList.value = []
        scopeApplication.value = value.scopeList.map(i => {
          // console.log('item', i)
          if (i.dataType == 2) {
            return {
              ...i,
              icon: require('@/assets/images/checkStaff/level.png')
            }
          } else if (i.dataType == 1) {
            return {
              ...i,
              icon: i.avatarId ? i.fullAvatar : require('@/assets/images/default-avatar.png')
            }
          }
        })
        value.scopeList = []
        scopeApplication.value?.forEach(item => {
          scopeList.value.push(item.dataName)
          value.scopeList.push({
            dataId: item.dataId,
            dataName: item.dataName,
            dataType: item.dataType
          })
        })
      } else {
        scopeList.value = 'all'
      }
      // 补盖审批
      coverPersonList.value = []
      if (value.coverType === 2) {
        coverPersonList.value = formateListData({ data: { staffList: value.coverList } })
        coverList.value = []
        basicForm.value.coverList = []
        coverPersonList.value?.forEach(item => {
          coverList.value.push(item.staffName)
          basicForm.value.coverList.push({
            staffId: item.staffId,
            staffName: item.staffName
          })
        })
      } else {
        coverList.value = 'business'
      }
      // 远程确认人 数组长度等于所有人的长度 说明选择了所有人
      remotePersonList.value = []
      // console.log('staffRemoteList.value',staffRemoteList.value, value.remoteAuditor, value.remoteAuditorVO);
      if (value.remoteAll || value.remoteAuditorVO === null) {
        remoteAuditor.value = 'every'
        basicForm.value.remoteAuditor = []
        basicForm.value.remoteAll = true
      } else {
        let list = []
        value.remoteAuditorVO?.forEach(element => {
          list.push({
            staffId: element.id,
            staffName: element.name,
            avatarId: element.avatarId,
            fullAvatar: element?.fullAvatar
          })
        })
        remotePersonList.value = formateListData({ data: { staffList: list } })
        console.log(remotePersonList.value)
        remoteAuditor.value = []
        basicForm.value.remoteAuditor = []
        remotePersonList.value?.forEach(item => {
          remoteAuditor.value.push(item.staffName)
          basicForm.value.remoteAuditor.push(item.staffId)
        })
      }
      // afterSealSnap.value = value.afterSealSnap === 1 // 是否抓拍
      // snapInterval.value = snapInterval.value ?? '1.5'
    }
  },
  { immediate: true }
)

const getInitList = async () => {
  const user = JSON.parse(localStorage.getItem('yda-admin-userInfo'))
  if (user) {
    custodianList.value.push({
      islower: false,
      staffName: user.nickname,
      dataName: user.nickname,
      dataType: 1,
      staffId: user.result.staffId,
      dataId: user.result.staffId,
      icon: user.avatar ? user.avatar : require('@/assets/images/default-avatar.png')
    })
  }
}

const getSeal = searchName => {
  return new Promise(resolve => {
    getSealList({ enterpriseId: enterpriseId.value, searchName }).then(res => {
      if (res.success) {
        console.log('获取到的印章信息', res)
        sealList.value = res.data
        state.checkList = [...formateListData(sealList.value, 2)]
        console.log('搜索到的值', state.checkList)
        resolve()
      }
    })
  })
}

// 流程名称输入框失焦
const getBlur = async (rule, value) => {
  // console.log('流程名称输入框失焦', rule, value)
  if (!value) {
    return Promise.reject('流程名称不可为空')
  } else {
    if (props.isDraft !== '1') {
      const params = {
        processMold: 1,
        processName: value,
        processId: basicForm.value.processId ?? undefined,
        processType: basicForm.value.processType ?? undefined
      }
      // console.log('判断是否已存在名字')
      const isRepeat = await getProcessName(params)
      if (!isRepeat.success) {
        return Promise.reject('流程名称不可重复')
      } else {
        return Promise.resolve()
      }
    } else {
      return Promise.resolve()
    }
  }
}

//切换业务类型
const changeProcessType = value => {
  Modal.confirm({
    title: '是否切换',
    width: '400px',
    content: '切换后表单设计中内容清空，是否确认切换？',
    centered: true,
    confirmLoading: loading.value,
    icon: createVNode(ExclamationOutlined),
    onOk: () => {
      basicForm.value.processType = value
      loadInitFrom(value)
      scopeList.value = []
      scopeApplication.value = []
      basicForm.value.scopeList = undefined
      isService.value = value == 1
      //关闭天玺录制视频开关
      basicForm.value.equipmentRec = 2
      //清空表单配置选中
      store.commit('setSelectFormItem', null)
    },
    onCancel: () => {
      basicForm.value.processType = value == 1 ? 2 : 1
    }
  })
}
// 切换开关
const checkChange = value => {
  console.log(value)
  basicForm.value.coverType = 1
  if (value) {
    coverList.value = 'business'
    basicForm.value.coverList = []
    coverPersonList.value = []
  }
  basicForm.value.coverSwitch = checked.value ? 1 : 2
}

// 用印前切换开关
// const setSealChange = (value, type) => {
//   console.log(value, type)
//   if (type === 'before') {
//     if (value) return (basicForm.value.beforeSealPhoto = 1)
//     // OCR 对比开着的时候弹窗
//     if (basicForm.value.ocrType == 1) {
//       // 关闭拍照时会关闭ocr
//       return Modal.confirm({
//         title: '是否关闭',
//         width: '400px',
//         content: '请确认是否关闭，如果关闭ocr功能无法正常使用',
//         centered: true,
//         confirmLoading: loading.value,
//         icon: createVNode(ExclamationOutlined),
//         onOk: () => {
//           basicForm.value.ocrType = 2 //关闭ocr识别 ocr状态(1. 启用 2. 关闭)
//           basicForm.value.beforeSealPhoto = 2
//         },
//         onCancel: () => {}
//       })
//     }
//     // OCR识别没开着 直接关闭
//     basicForm.value.beforeSealPhoto = 2
//   } else {
//     basicForm.value.afterSealSnap = value ? 1 : 2
//   }
// }

// 选择印章
const sealChange = value => {
  getSeal().then(() => {
    switch (value) {
      case 1: //所有印章
        console.log('获取到的所有印章数据sealList.value', sealList.value)
        changeSeal.value = value
        sealSelectType.value = null
        basicForm.value.sealList = []
        sealList.value.forEach(item => {
          basicForm.value.sealList.push({
            sealId: item.id
          })
        })
        basicForm.value.sealRange = value

        formRef.value.validateField(['sealList'], err => {
          console.log('err', err)
        })
        break
      case 2: //指定印章
        currentType.value = 'seal'
        changeSeal.value = []
        basicForm.value.sealList = []
        console.log('查看值', alreadySelectData.value)
        selectedList.value = alreadySelectData.value //弹窗右侧已选列表的值
        state.modalShow = true
        break
    }
  })
}

// 关闭弹窗
const closeModal = type => {
  if (type == 'cover') {
    if (coverPersonList.value.length != 0) {
      coverPersonList.value.forEach(item => {
        coverList.value.push(item.dataName)
      })
    }
    formRef.value.validateField(['coverList'], err => {
      console.log('err', err)
    })
  } else if (type == 'business') {
    // 流程名称负责人
  } else if (type == 'range') {
    // 适用范围
    console.log('range', scopeApplication.value)
    scopeApplication.value &&
      scopeApplication.value.forEach(item => {
        scopeList.value.push(item.dataName)
        basicForm.value.scopeList.push({
          dataId: item.dataId,
          dataName: item.dataName,
          dataType: item.dataType
        })
      })
    formRef.value.validateField(['scopeList'], err => {
      console.log('err', err)
    })
  } else if (type === 'seal') {
    changeSeal.value = basicForm.value.sealRange == 1 ? 1 : alreadySelectData.value.map(item => item.dataName)
    basicForm.value.sealList = alreadySelectData.value.map(item => {
      return {
        sealId: item.dataId,
        sealName: item.dataName
      }
    })
    formRef.value.validateField(['sealList'], err => {
      console.log('err', err)
    })
  } else if (type === 'remote') {
    remotePersonList.value &&
      remotePersonList.value.forEach(item => {
        remoteAuditor.value.push(item.dataName)
      })
    basicForm.value.remoteAuditor = remotePersonList.value.map(item => {
      return item.dataId
    })
    formRef.value.validateField(['remoteAuditor'], err => {
      console.log('err', err)
    })
  }
  state.modalShow = false
  globalSelector.value.cancelEvent()
}
// 部门子级(补盖审批人、流程名称负责人)
const tolowerMdel = async id => {
  let data = {
    departmentId: id
  }
  getScopeList(data)
}

//初始列表(补盖审批人、流程名称负责人 远程确认人)
const getScopeList = async data => {
  let params = {
    selectorType: selectorType.value, //1部门人员2部门3员工
    allData: true
  }
  // console.log('拼接后的参数', { ...params, ...data })
  const res = await getDepartmentOrStaff({ ...params, ...data })
  let list = formateListData(res)
  state.checkList = list
  // console.log('res', list)
}
//搜索请求
const getSearchInfo = async data => {
  if (currentType.value === 'seal') {
    //关联印章
    getSeal(data)
  } else {
    // 补盖审批人、流程名称负责人
    let params = {
      selectorType: selectorType.value,
      searchName: data,
      allData: true
    }
    const res = await getSearchData(params)
    let list = formateListData(res)
    state.checkList = list
  }
}

const getSelect = () => {
  console.log(scopeList.value.length)
  if (!scopeList.value || scopeList.value.length === 0 || scopeList.value === 'personnel') {
    return Promise.reject('请设置适用范围')
  } else {
    return Promise.resolve()
  }
}
const getCover = () => {
  console.log(coverList.value)
  if (
    (coverList.value === '' || coverList.value.length === 0 || coverList.value === 'appointPersonnel') &&
    basicForm.value.coverSwitch === 1
  ) {
    return Promise.reject('请设置补盖审批人')
  } else {
    return Promise.resolve()
  }
}

const sealVaild = (rules, value) => {
  if (!changeSeal.value || changeSeal.value.length === 0) {
    return Promise.reject('关联印章不可为空')
  } else {
    return Promise.resolve()
  }
}

const getRemote = () => {
  // console.log(remoteAuditor.value)
  if (remoteAuditor.value === '' || remoteAuditor.value.length === 0 || remoteAuditor.value === 'designee') {
    return Promise.reject('请设置远程确认人')
  } else {
    return Promise.resolve()
  }
}

const rules = {
  icon: [{ required: true, message: '图标不可为空' }],
  name: [{ required: true, validator: getBlur, trigger: 'blur' }],
  processType: [{ required: true, message: '请选择业务类型' }],
  sealList: [
    {
      required: true,
      validator: sealVaild
    }
  ],
  scopeList: [{ required: true, validator: getSelect }],
  custodianList: [
    {
      required: true,
      validator: () => {
        if (basicForm.value.custodianList.length === 0) {
          return Promise.reject('请设置业务流程负责人')
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  coverList: [{ required: false, validator: getCover }],
  remoteAuditor: [{ required: true, validator: getRemote }]
}

// 校验
async function validateForm() {
  await convert()
  formRef.value
    .validate()
    .then(res => {
      console.log(res)
    })
    .catch(err => {
      console.log('1938962', err)
    })
}
defineExpose({ validateForm })

const convert = async () => {
  // 这是
  let deptIds = []
  basicForm.value.coverList = []
  coverPersonList.value.forEach(item => {
    // 部门转人员id
    if (item.dataType === 2) {
      deptIds.push(item.dataId)
    } else {
      basicForm.value.coverList.push({
        staffId: item.dataId,
        staffName: item.dataName
      })
    }
  })
  console.log('这是', coverPersonList.value)
  const res = await transformPeople({ deptIds })
  if (res.success && res.data?.length) {
    res.data.forEach(i => {
      console.log(i)
      const index = basicForm.value.coverList.findIndex(it => it.staffId === i.staffId)
      if (index !== -1) return
      basicForm.value.coverList.push({
        staffId: i.staffId,
        staffName: i.staffName
      })
    })
  }
  console.log('basicForm.value.coverList', basicForm.value.coverList)
}

// 鼠标移入/移出
const mouseenter = () => {
  if (icon.value === '') return
  isMouse.value = true
}
const mouseleave = () => {
  isMouse.value = false
}

// 选择图标
const getIcon = item => {
  // console.log(item)
  basicForm.value.icon = item.key
  icon.value = item.icon
  showIcon.value = false
  formRef.value.validateField(['icon'], err => {
    console.log('err', err)
  })
}

// 显示弹窗
const showProModal = type => {
  if (type == 1) {
    currentType.value = 'business'
    getScopeList()
  }
  // console.log(type)
  const modalTitleObj = {
    1: '选择业务流程负责人',
    2: '选择部门或人员',
    3: '选择补盖审批人',
    4: '选择远程确认人',
    11: '选择部门'
  }
  state.modalShow = true
  modalTitle.value = modalTitleObj[type]
  modalType.value = type
  checkAll.value = type === 1
  // console.log('scopeApplication.value',scopeApplication.value);
  if (type === 2 || type === 11) {
    selectedList.value = scopeApplication.value
  } else if (type === 1) {
    selectedList.value = custodianList.value
  } else if (type == 3) {
    selectedList.value = coverPersonList.value
  } else {
    selectedList.value = remotePersonList.value
  }
  checkAllLimit.value = type === 1 ? 10 : null
}

// 点击弹窗确认
const handledOk = async type => {
  console.log('我的数据', type, globalSelector.value.okEvent())
  let checkList = [...globalSelector.value.okEvent()]
  // type:  cover补盖审批人        business流程名称负责人       range适用范围        remote远程确认人
  if (type == 'cover') {
    // 补盖审批人
    basicForm.value.coverList = []
    coverList.value = []
    //如果之前有数据
    console.log('111', coverPersonList.value, basicForm.value.coverList, coverList.value)
    transformData(checkList).then(res => {
      res.length &&
        res.forEach(item => {
          coverList.value.push(item.dataName)
        })
      coverPersonList.value = [...res]
      basicForm.value.coverType = 2
      formRef.value.validateField(['coverList'], err => {
        console.log('err', err)
      })
    })
  } else if (type == 'business') {
    // 流程名称负责人
    custodianList.value = []
    basicForm.value.custodianList = []
    transformData(checkList).then(res => {
      res.length &&
        res.forEach(item => {
          custodianList.value.push({
            ...item,
            staffId: item.dataId,
            staffName: item.dataName
          })
          basicForm.value.custodianList.push({
            ...item,
            staffId: item.dataId,
            staffName: item.dataName
          })
        })
      formRef.value.validateField(['custodianList'], err => {
        console.log('err', err)
      })
    })
    // checkList &&
    //   checkList.forEach(item => {
    //     custodianList.value.push({
    //       ...item,
    //       staffId: item.dataId,
    //       staffName: item.dataName
    //     })
    //     basicForm.value.custodianList.push({
    //       ...item,
    //       staffId: item.dataId,
    //       staffName: item.dataName
    //     })
    //   })
  } else if (type == 'range') {
    // 适用范围
    scopeApplication.value = checkList
    basicForm.value.scopeList = []
    scopeList.value = []
    console.log(basicForm.value)
    checkList &&
      checkList.forEach(item => {
        basicForm.value.scopeList.push({
          dataId: item.dataId,
          dataName: item.dataName,
          dataType: item.dataType
        })
        scopeList.value.push(item.dataName)
      })
    formRef.value.validateField(['scopeList'], err => {
      console.log('err', err)
    })
    basicForm.value.scopeType = 2
  } else if (type === 'seal') {
    //关联印章
    let arr = [...checkList]
    console.log('选中的数据', arr)
    sealSelectType.value = 'multiple'
    //关联印章选择框展示内容回显
    changeSeal.value = arr.map(item => item.dataName)
    basicForm.value.sealRange = 2
    basicForm.value.sealList = arr.map(item => {
      return {
        sealId: item.dataId,
        sealName: item.dataName
      }
    })
    //弹窗内已选列表回显
    alreadySelectData.value = [...arr]
    formRef.value.validateField(['sealList'], err => {
      console.log('err', err)
    })
  } else if (type === 'remote') {
    basicForm.value.remoteAuditor = []
    remoteAuditor.value = []
    transformData(checkList).then(res => {
      res.length &&
        res.forEach(item => {
          remoteAuditor.value.push(item.dataName)
          basicForm.value.remoteAuditor.push(item.dataId)
        })
      remotePersonList.value = [...res]

      formRef.value.validateField(['remoteAuditor'], err => {
        console.log('err', err)
      })
    })
  }
  state.modalShow = false
  globalSelector.value.cancelEvent()
}

// 下拉框选项
const changePri = value => {
  console.log('我是下拉选择', value, basicForm.value.processType)
  if (['personnel', 'all'].includes(value)) {
    if (value === 'all') {
      scopeList.value = value
      basicForm.value.scopeType = 1
      basicForm.value.scopeList = []
      scopeApplication.value = []
    }
    if (value === 'personnel') {
      currentType.value = 'range'
      getScopeList()
      scopeList.value = []
      basicForm.value.scopeType = 2
      basicForm.value.scopeList = []
      basicForm.value.processType == 1 ? showProModal(2) : showProModal(11)
    }
    formRef.value.validateField(['scopeList'], err => {
      console.log('err', err)
    })
  }
  // 补盖审批人选项
  if (['business', 'appointPersonnel'].includes(value)) {
    if (value === 'business') {
      coverList.value = 'business'
      basicForm.value.coverType = 1
      basicForm.value.coverList = []
      coverPersonList.value = []
    }
    if (value === 'appointPersonnel') {
      currentType.value = 'cover'
      state.modalShow = true
      getScopeList()
      coverList.value = []
      basicForm.value.coverType = 2
      selectedList.value = coverPersonList.value
      modalType.value = 3
    }
    formRef.value.validateField(['coverList'], err => {
      console.log('err', err)
    })
  }
  // 远程确认人
  if (['every', 'designee'].includes(value)) {
    if (value === 'every') {
      remoteAuditor.value = 'every'
      // basicForm.value.remoteAuditor = staffRemoteList.value
      basicForm.value.remoteAll = true
      remotePersonList.value = []
    }
    if (value === 'designee') {
      currentType.value = 'remote'
      state.modalShow = true
      modalType.value = 4
      remoteAuditor.value = []
      selectedList.value = remotePersonList.value
      getScopeList()
      basicForm.value.remoteAll = false
      basicForm.value.remoteAuditor = []
      showProModal(4)
      formRef.value.validateField(['remoteAuditor'], err => {
        console.log('err', err)
      })
    }
  }
}

// 删除
const delSelect = (value, type) => {
  console.log(value, type)
  // 补盖审批人
  if (type === 'cover') {
    const coverIndex = coverPersonList.value.findIndex(item => item.staffName === value)
    coverPersonList.value.splice(coverIndex, 1)
    basicForm.value.coverList.splice(coverIndex, 1)
    if (coverList.value.length === 0) {
      formRef.value.validateField(['coverList'], err => {
        console.log('err', err)
      })
    }
    console.log('11111', coverPersonList.value)
  } else if (type == 'seal') {
    const sealIndex = alreadySelectData.value.findIndex(item => item.dataName === value)
    alreadySelectData.value.splice(sealIndex, 1)
    basicForm.value.sealList.splice(sealIndex, 1)
    if (!alreadySelectData.value.length) {
      formRef.value.validateField(['sealList'], err => {
        console.log('err', err)
      })
    }
  } else if (type === 'range') {
    const rangeIndex = scopeApplication.value.findIndex(item => item.dataName === value)
    scopeApplication.value.splice(rangeIndex, 1)
    basicForm.value.scopeList.splice(rangeIndex, 1)
    if (scopeList.value.length === 0) {
      formRef.value.validateField(['scopeList'], err => {
        console.log('err', err)
      })
    }
  } else if (type === 'remote') {
    const remoteIndex = remotePersonList.value.findIndex(item => item.dataName === value)
    remotePersonList.value.splice(remoteIndex, 1)
    basicForm.value.remoteAuditor.splice(remoteIndex, 1)
    basicForm.value.remoteAuditorVO.splice(remoteIndex, 1)
    if (remoteAuditor.value.length === 0) {
      formRef.value.validateField(['remoteAuditor'], err => {
        console.log('err', err)
      })
    }
  }
}

// 删除选项
const deleteSta = (index, type) => {
  custodianList.value.splice(index, 1)
  basicForm.value.custodianList.splice(index, 1)
}

// 切换数据
const changeData = ({ isOrganizationData, searchValue }) => {
  console.log('切换的数据的类型', isOrganizationData)
  if (isOrganizationData) {
    // 组织架构数据
    selectorType.value = 1
  } else {
    //人员数据
    selectorType.value = 3
  }
  getSearchInfo(searchValue)
}

// 关闭时提示框
// const alterConfirm = () => {
//   console.log(makeUp.value)
//   basicForm.value.makeUp = makeUp.value ? 1 : 2
//   if (!makeUp.value) {
//     Modal.confirm({
//       title: '确认关闭',
//       icon: createVNode(ExclamationOutlined),
//       content: '关闭后所有文件均无须强制补拍，是否确认关闭？',
//       okText: '确认',
//       cancelText: '取消',
//       centered: true,
//       onCancel() {
//         makeUp.value = true
//         basicForm.value.makeUp = 1
//       }
//     })
//   }
// }
</script>

<style lang="scss" scoped>
body {
  position: relative;
  overflow: hidden;
}

.foot {
  margin: 0px auto;
  width: 100vw;
  background-color: #f0f2f5;
  line-height: 60px;
  margin-left: -240px;
}

.basic {
  background-color: #fff;
  padding: 32px 70px;
  border-radius: 2px;
  width: 600px;
  margin: 0px auto;
  text-align: left;
  margin-top: 60px;

  .icon {
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #f3f3f3;
    border: 1px solid #e1e1e1;
    position: relative;
    text-align: center;

    // overflow: hidden;
    .icon-img {
      width: 100%;
      z-index: 8;
    }

    .icon-initial {
      color: #d8d8d8;
      font-size: 26px;
      line-height: 64px;
    }

    .icon-hover {
      background-color: rgba(0, 0, 0, 0.5);
      width: 60px;
      height: 20px;
      text-align: center;
      color: #fff;
      line-height: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 12px;
      z-index: 10;
      clip-path: circle(65% at 49% -45%);
    }
  }

  .tips-remote {
    position: absolute;
    top: 4px;
  }

  .proces {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;

    .process-up {
      width: 40px;
      height: 40px;
    }

    .pro-item {
      .pro-item-icon {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        object-fit: cover;
      }

      .remove-arrow {
        position: relative;
        bottom: 30px;
        right: 12px;
        color: #c3161c;
        font-size: 12px;
        background: #ffffff;
        height: 12px;
        line-height: 12px;
        border-radius: 50%;
        cursor: pointer;
      }

      .lang-text-name {
        cursor: pointer;
        width: 50px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .checked {
    position: relative;
    top: -6px;
    left: 120px;
  }

  .approval {
    :deep(.ant-form-item-control) {
      margin-top: -26px;
    }
  }

  .range,
  .approval {
    .printer {
      width: 460px;
      min-height: 36px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border: 1px solid #d9d9d9;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      line-height: 32px;

      &:hover {
        border-color: #cf3838;
      }
    }

    .no-range {
      color: #ff4d4f;
      margin-top: 8px;
    }

    .no-input {
      border-color: #0A7BFF;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow:none;
    }
  }

  .switch {
    margin-left: 8px;
  }

  .snap {
    display: flex;
    margin-top: 18px;

    .snap-right {
      margin-left: 16px;
    }
  }
}

.form-item {
  margin-top: -12px;
}

.modal {
  display: flex;
  flex-wrap: wrap;
  width: 336px;
  gap: 16px;

  .modal-item {
    width: 60px;
    height: 60px;
    border: 1px solid #f5f5f5;
    border-radius: 14px;
    padding: 10px;
    cursor: pointer;

    .item-icon {
      width: 40px;
      height: 40px;
    }

    .icon-sel {
      width: 24px;
      height: 24px;
      position: relative;
      top: -22px;
      right: -24px;
    }
  }
}

.deactivate {
  width: 32px;
  height: 18px;
  background: #ebedf0;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  color: #323233;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
}

.dept-name {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
  max-width: 14em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

:deep(.ant-select-show-search.ant-select-multiple .ant-select-selector) {
  max-height: 100px;
  overflow: auto;
}
</style>
<style lang="scss">
.lang-name-pop {
  .ant-popover-inner-content {
    max-width: 300px;
  }
}

.range,
.approval {
  .ant-select-arrow {
    top: 16px;
  }
}

.option-stop {
  margin-left: 16px;
  background-color: #ebedf0;
  border-radius: 2px;
  font-size: 12px;
  color: #323233;
  padding: 0 4px;
}

.ant-select-dropdown
  .ant-select-dropdown-content
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f0f0f0;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
}
</style>
